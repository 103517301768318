<template>
  <b-sidebar
    id="edit-sidebar"
    :visible="isListEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-list-edit-sidebar-active', val)"

  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Designation
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="designation"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="editData.designation"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Designation Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isListEditSidebarActive',
    event: 'update:is-list-edit-sidebar-active',
  },
  props: {
    isListEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    editData: {
      required: true
    }
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    onSubmit() {
      store.dispatch('app-designation/editData', this.editData)
        .then((response) => {
          if(response.data.status == 1 || response.data.success){
            this.$emit('refetch-data')
            this.$emit('update:is-list-edit-sidebar-active', false)
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully updated!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
          }
          else if(response.data.status == 0){
              
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  title: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
      })
    }
  },
  setup(props, { emit }) {
    const resetData = () => {
      emit('refetch-data')
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
